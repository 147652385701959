import { verify } from './verifyConfig'

const config = {
  env: process.env.REACT_APP_STAGE,
  debug: process.env.REACT_APP_DEBUG === 'true',
  cookies: {
    auth: '8gy7qDkkNe',
    user: 'npERSVsQkK'
  },
  localStorage: {
    colorScheme: 'app-color-scheme'
  },
  app: {
    name: 'Boilerplate'
  },
  encryption: {
    key: process.env.REACT_APP_CRYPTO_KEY as string
  },
  api: {
    url: process.env.REACT_APP_API_URL,
    timeout: 60 * 1000 // API request timeout set to 60s
  },
  date: {
    dateFormat: 'YYYY年MM月DD日'
  }
}

// Check if config object is valid and not missing a key
const validate = verify(config)

if (!validate) {
  console.error('No valid environment found')
}
if (validate?.length !== 0) {
  console.error(`Environment variable missing: ${validate}`)
}

export default config
