import { useNavigate } from 'react-router-dom'
import { Badge, Button } from '@mantine/core'

import { useFetch, useQuery } from '../../hooks'
import { Customer, ColumnsFactory, DataTableGenerics } from '../../typings'

import PageLayout from '../../components/PageLayout/PageLayout'
import { SimpleTable } from '../../components/Table'
import dayjs from 'dayjs'
import config from '../../config'
import { Row } from '@tanstack/react-table'

const breadcrumbs = [{
  title: 'Customers',
  to: '/customers'
}]

export default function CustomersPage () {
  const navigate = useNavigate()
  const { params } = useQuery()
  const { isLoading, data } = useFetch<Customer[]>({
    url: '/customers',
    params
  })

  const _handleRowClicked = (row: Row<DataTableGenerics<Customer>>) => {
    navigate(`./${row.id}`)
  }

  const _navigateToAdd = () => {
    navigate('./add')
  }

  const createColumns: ColumnsFactory<Customer> = (table) => [
    table.createDataColumn('first_name', {
      cell: info => info.getValue(),
      header: () => <span>First Name</span>
    }),
    table.createDataColumn(row => row.last_name, {
      id: 'last_name',
      cell: info => info.getValue(),
      header: () => <span>Last Name</span>
    }),
    table.createDataColumn('email', {
      filterFn: 'stringFilterFn'
    }),
    table.createDataColumn('birthday', {
      cell: ({ cell }) => {
        if (cell) return dayjs(cell.getValue()).format(config.date.dateFormat)
        return null
      }
    }),
    table.createDataColumn('active', {
      cell: ({ cell }) => (
        cell.getValue()
          ? <Badge size="md" color="blue" variant="light">Active</Badge>
          : <Badge size="md" color="gray" variant="filled">Inactive</Badge>
      )
    })
  ]

  return (
    <PageLayout
      pageTitle="Customers"
      title="Customer list"
      breadcrumbs={breadcrumbs}
      titleRightArea={<Button onClick={_navigateToAdd}>Add customer</Button>}
    >
      <SimpleTable<Customer>
        loading={isLoading}
        columns={createColumns}
        data={data || []}
        containerProps={{}}
        onRowClick={_handleRowClicked}
        withPagination
        withGlobalFilter
      />
    </PageLayout>
  )
}
