import { useState } from 'react'
import { AppShell, createStyles } from '@mantine/core'
import { Outlet } from 'react-router-dom'
import HeaderNavigation from '../components/navigation/HeaderNavigation'
import BurgerNavigation from '../components/navigation/BurgerNavigation'

export default function MainLayout () {
  const { classes } = useStyles()
  const [opened, setOpened] = useState(false)
  return (
    <AppShell
      padding="md"
      navbarOffsetBreakpoint="sm"
      header={<HeaderNavigation opened={opened} setOpened={setOpened} />}
      navbar={<BurgerNavigation opened={opened} />}
      classNames={{
        main: classes.main
      }}
      fixed
    >
      <Outlet />
    </AppShell>
  )
}

const useStyles = createStyles((theme) => ({
  main: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
    height: '100vh',
    overflow: 'scroll'
  }
}))
