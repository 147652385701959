import { forwardRef } from 'react'
import {
  Avatar,
  createStyles,
  Group,
  Text,
  UnstyledButton
} from '@mantine/core'
import { ChevronRight } from 'tabler-icons-react'
import PropTypes from 'prop-types'

interface UserButtonProps {
  image?: string;
  name?: string;
  email?: string;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, name, email, ...props }: UserButtonProps, ref) => {
    const { classes } = useStyles()
    return (
      <UnstyledButton
        ref={ref}
        className={classes.button}
        {...props}
      >
        <Group>
          <Avatar src={image} radius="xl" />

          <div className={classes.userInfo}>
            <Text size="sm" weight={500}>
              {name}
            </Text>

            <Text className={classes.userEmail} size="xs">
              {email}
            </Text>
          </div>

          <ChevronRight size={16} />
        </Group>
      </UnstyledButton>
    )
  }
)

UserButton.displayName = 'UserButton'

UserButton.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
}

const useStyles = createStyles((theme) => ({
  button: {
    display: 'block',
    width: '100%',
    padding: theme.spacing.md,
    color: theme.white,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.teal[8]
    }
  },
  userInfo: {
    flex: 1
  },
  userEmail: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[3]
  }
}))

export default UserButton
