import * as yup from 'yup'

export const loginSchema = yup.object().shape({
  email: yup.string()
    .email()
    .nullable()
    .required()
    .label('Email'),
  password: yup.string()
    .nullable()
    .required()
    .label('Password'),
  remember: yup.bool()
    .nullable()
    .required()
    .label('Remember me')
})
