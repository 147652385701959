import { AxiosResponse } from 'axios'
import { Customer } from '../typings'
import { api } from '../utils'

export const postCustomer = async (data: Customer): Promise<AxiosResponse<unknown>> => {
  return api.post('/customers', data)
}
export const deleteCustomer = async (id: number): Promise<AxiosResponse<unknown>> => {
  return api.delete(`/customers/${id}`)
}
