import { Helmet as HelmetAsync } from 'react-helmet-async'
import PropTypes from 'prop-types'
import config from '../../config'

type Props = {
  title?: string;
}

function Helmet ({ title }: Props) {
  return (
    <HelmetAsync>
      <title>{`${title && `${title} | `}`} {config.app.name}</title>
    </HelmetAsync>
  )
}

Helmet.propTypes = {
  title: PropTypes.string
}

export default Helmet
