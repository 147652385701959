import MockAdapter from 'axios-mock-adapter'
import { api } from '../utils'

import loginRequest from './mock/login.json'
import customersSmallResponse from './mock/customers_small.json'
import customersResponse from './mock/customers.json'
import customerResponse from './mock/customer.json'

const mock = new MockAdapter(api, { delayResponse: 300 })

mock.onPost('/login').reply(200, loginRequest)
mock.onPost('/logout').reply(204)

mock.onGet('/customers').reply(200, customersSmallResponse)
mock.onGet(/\/customers\/\d+/).reply(() => [200, customerResponse])
mock.onPost('/customers').reply(204)
mock.onDelete(/\/customers\/\d+/).reply(204)
