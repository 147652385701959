import { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { parse, stringifyUrl, ParsedQuery } from 'query-string'

const setQueryStringWithoutPageReload = (query: ParsedQuery) => {
  const baseURL = window.location.protocol + '//' +
                 window.location.host +
                 window.location.pathname
  const newurl = stringifyUrl({ url: baseURL, query }, {
    skipEmptyString: true,
    skipNull: true
  })

  window.history.pushState({ path: newurl }, '', newurl)
}
const updateParams = (query: ParsedQuery) => {
  const values = parse(window.location.search)
  const newQuery = {
    ...values,
    ...query
  }
  setQueryStringWithoutPageReload(newQuery)
}

export function useQuery () {
  const location = useLocation()
  const [params, setParams] = useState(parse(location.search))

  const updateQueryParameters = (query: ParsedQuery) => {
    let updated = false
    for (const param in params) {
      if (!updated) {
        const value = params[param]
        if (!query[param] && value && query[param]) {
          updated = true
        } else if (query[param] !== value) {
          updated = true
        }
      }
    }
    if (updated) {
      updateParams(query)
      setParams(prev => ({ ...prev, ...query }))
    }
  }

  const memoedValue = useMemo(
    () => ({
      params,
      updateQueryParameters
    }),
    [params]
  )

  return memoedValue
}
