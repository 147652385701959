import axios, { AxiosRequestConfig } from 'axios'

import config from '../config'
import { CustomError } from '../typings'
import {
  getAccessToken,
  getRefreshToken,
  isAccessTokenValid,
  isRefreshTokenValid,
  logoutUser,
  updateTokens
} from './auth'

const getHeaders = async (config: AxiosRequestConfig<any>) => {
  // If no token at all, just return null (empty header)
  if (!getRefreshToken()) return null

  // If the refresh token is still valid, check the access token
  if (isRefreshTokenValid()) {
    if (isAccessTokenValid()) {
      return { Authorization: `Bearer ${getAccessToken()}` }
    } else {
      try {
        const res = await axios.get(`${config.baseURL}/refresh_token`, {
          headers: {
            Authorization: `Bearer ${getRefreshToken()}`
          }
        })
        const { access_token, refresh_token } = res.data
        // Logout user if both token are not present
        if (!access_token || !refresh_token) {
          console.warn('Headers error #0001')
          logoutUser()
        }

        // Update tokens in storage & return updated header
        updateTokens(access_token, refresh_token)
        return { Authorization: `Bearer ${access_token}` }
      } catch (error: any) {
        console.error('err', error.response.status)
        console.error('err', error.response.data)
      }
    }
  } else {
    // if the refresh token has expired, logout the user and ask to re-login
    console.warn('Headers error #0002')
    logoutUser()
  }
  return null
}

export const api = axios.create({
  baseURL: config.api.url,
  timeout: config.api.timeout
})
api.interceptors.request.use(async config => {
  const headers = await getHeaders(config)
  if (headers) config.headers = headers
  // console.log('config', config)
  return config
}, error => {
  console.log('req error', error)
  Promise.reject(error)
})
api.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log('response', response)
  return response
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log('res err', error)
  const err: CustomError = new Error()
  err.data = {
    status: error?.response?.status || 500,
    message: 'サーバエラー'
  }
  if (error?.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (
      error.request?.responseType === 'blob' &&
      error.response?.data?.type &&
      error.response?.data?.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new window.FileReader()
        reader.onload = () => {
          if (reader.result) {
            const data = JSON.parse(reader.result as string)
            err.data = data
            resolve(Promise.reject(err))
          }
        }

        reader.onerror = () => {
          reject(error)
        }

        reader.readAsText(error.response.data)
      })
    }
    // console.log('data', error.response.data)
    // console.log('req', error.request)
    // console.log('msg', error.message)
    // console.log(error.response.status)
    // console.log(error.response.headers)
    err.data = { raw: error.response?.data, status: error.response?.status, message: error?.message }
  }
  return Promise.reject(err)
})

export const multipartHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}
