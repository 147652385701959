import { LoadingOverlay, MantineProvider } from '@mantine/core'
import { NotificationsProvider } from '@mantine/notifications'
import { useHotkeys } from '@mantine/hooks'
import { HelmetProvider } from 'react-helmet-async'

import { theme, styles } from './theme'
import { useAuth, useColorScheme } from '../hooks'

import Router from './Router'
import Helmet from '../components/Helmet/Helmet'

function App () {
  const { colorScheme, toggleScheme } = useColorScheme()
  const { loading } = useAuth()

  useHotkeys([['mod+J', toggleScheme]])

  if (loading) return <LoadingOverlay visible={true} loaderProps={{ variant: 'bars' }} />

  return (
    <HelmetProvider>
      <MantineProvider theme={{ ...theme, colorScheme }} styles={styles} withNormalizeCSS>
        <NotificationsProvider
          position="top-right"
          limit={3}
          autoClose={2000}
        >
          <Helmet />
          <Router />
        </NotificationsProvider>
      </MantineProvider>
    </HelmetProvider>
  )
}

export default App
