import { useRef } from 'react'
import { createTable, Table } from '@tanstack/react-table'

import { DataTableGenerics } from '../typings'
import { stringFilterFn } from '../components/Table/utils'

export function useReactTable<T> () {
  const { current: table } = useRef(
      createTable().setRowType<T>().setOptions({
        filterFns: {
          stringFilterFn
        }
      }) as Table<DataTableGenerics<T>>
  )
  return table
}
