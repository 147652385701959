import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ActionIcon,
  Drawer,
  Text,
  createStyles,
  Image,
  Badge,
  Box
} from '@mantine/core'
// import PropTypes from 'prop-types'

import { useFetch } from '../../hooks'
import { Customer } from '../../typings'

import PageLayout from '../../components/PageLayout/PageLayout'
import { Pencil, Trash } from 'tabler-icons-react'
import Confirm from '../../components/Confirm/Confirm'
import CustomerForm from './CustomerForm'
import dayjs from 'dayjs'
import config from '../../config'

export default function CustomerPage () {
  const { classes } = useStyles()
  const { customerId } = useParams()
  const [showDrawer, setShowDrawer] = useState(false)
  const { isLoading, data } = useFetch<Customer>({
    url: `/customers/${customerId}`
  })

  const breadcrumbs = [
    {
      title: 'Customers',
      to: '/customers'
    },
    {
      title: 'Customer Details',
      to: `/customers/${customerId}`
    }
  ]

  const _toggleDrawer = () => {
    setShowDrawer(current => !current)
  }

  const _onEdit = () => {
    _toggleDrawer()
  }
  const _onDelete = () => {
    console.log('delete customer', customerId)
  }

  return (
    <PageLayout
      pageTitle="Customer Details"
      title="Customer details"
      breadcrumbs={breadcrumbs}
      loading={isLoading}
      titleRightArea={
        <>
          <ActionIcon
            onClick={_onEdit}
            variant="filled"
            color="blue"
            size="lg"
          >
            <Pencil size={16} />
          </ActionIcon>
          <Confirm
            onConfirm={_onDelete}
            onConfirmText='Delete'
            onConfirmColor='red'
            message="Delete customer?"
          >
          <ActionIcon
            variant="filled"
            color="red"
            size="lg"
          >
            <Trash size={16} />
          </ActionIcon>
          </Confirm>
        </>
      }
    >
      <Text weight={700}>Customer name</Text>
      <Text>{data ? `${data?.last_name} ${data?.first_name}` : ''}</Text>
      <Text weight={700} mt="md">Customer email</Text>
      <Text>{data?.email || ''}</Text>
      <Text weight={700} mt="md">Customer birthday</Text>
      <Text>{data?.birthday ? dayjs(data.birthday).format(config.date.dateFormat) : ''}</Text>
      <Text weight={700} mt="md">Customer picture</Text>
      <Image
        src={data?.picture}
        withPlaceholder
        width={200}
        radius="xl"
        mt="xs"
      />
      <Text weight={700} mt="md" mb="xs">Active customer</Text>
      <Box>
        {
          data?.active
            ? <Badge size="lg" color="blue" variant="light">Active</Badge>
            : <Badge size="lg" color="gray" variant="filled">Inactive</Badge>
        }
      </Box>
      <Text weight={700} mt="md" mb="xs">Memo</Text>
      <Text>{data?.memo || ''}</Text>
      {
        data && (
          <Drawer
            closeOnClickOutside={false}
            opened={showDrawer}
            onClose={_toggleDrawer}
            title='Edit Customer'
            position="right"
            padding="xl"
            size="xl"
            aria-labelledby="Edit customer drawer"
            aria-describedby="Customer info and edit form"
            closeButtonLabel="Close drawer"
            classNames={{ drawer: classes.drawer }}
          >
            <div className={classes.drawerBody}>
              <CustomerForm initialData={data} />
            </div>
          </Drawer>
        )
      }
    </PageLayout>
  )
}

CustomerPage.propTypes = {

}

const useStyles = createStyles((theme) => ({
  drawer: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.white : theme.colors.dark[9],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll'
  },
  drawerBody: {
    flex: 1
  }
}))
