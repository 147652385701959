import { createStyles, Navbar } from '@mantine/core'
import PropTypes from 'prop-types'
import Links from './Links'

type Props = {
  opened: boolean;
}

function BurgerNavigation ({ opened }: Props) {
  const { classes } = useStyles()
  return (
    <Navbar
      className={classes.navbar}
      width={{ base: '100%', sm: 0 }}
      hidden={!opened}
    >
      <Links />
    </Navbar>
  )
}

BurgerNavigation.propTypes = {
  opened: PropTypes.bool.isRequired
}

export default BurgerNavigation

const useStyles = createStyles((theme) => ({
  navbar: {
    padding: 16,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.blue[9] : theme.colors.blue[6],
    [theme.fn.largerThan('sm')]: {
      display: 'none'
    }
  }
}))
