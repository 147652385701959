import {
  Burger,
  createStyles,
  Group,
  Header,
  MediaQuery
} from '@mantine/core'
import { useAuth } from '../../hooks'

import Links from './Links'
import UserMenu from './UserMenu'

type Props = {
  opened: boolean;
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HeaderNavigation ({ opened, setOpened }: Props) {
  const { classes } = useStyles()
  const { user } = useAuth()

  function _toggleBurger () {
    setOpened((o) => !o)
  }

  if (!user) return null
  return (
    <Header
      height={92}
      classNames={{
        root: classes.root
      }}
      fixed
    >
      <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
        <Burger
          opened={opened}
          onClick={_toggleBurger}
          size="sm"
          mr="xl"
        />
      </MediaQuery>
      <div className={classes.links}>
        <Group>
          <Links />
        </Group>
        <UserMenu />
      </div>
    </Header>
  )
}

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.teal[9],
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    paddingRight: theme.spacing.md,
    paddingLeft: theme.spacing.md
  },
  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none'
    },
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
