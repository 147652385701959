import { Location, useLocation, useNavigate } from 'react-router-dom'
import { AppShell, Button, Card, Center, Checkbox, createStyles, Group, PasswordInput, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { useNotifications } from '@mantine/notifications'
import { Send } from 'tabler-icons-react'

import { loginSchema } from '../validation'

import Helmet from '../components/Helmet/Helmet'
import { useEffect } from 'react'
import { useAuth } from '../hooks'

type LocationCustomState = {
  from: Location
}

export default function LoginPage () {
  const { classes } = useStyles()
  const notifications = useNotifications()
  const navigate = useNavigate()
  const location = useLocation()
  const { loading, user, login } = useAuth()
  const { onSubmit, getInputProps, values } = useForm({
    schema: yupResolver(loginSchema),
    initialValues: {
      email: '',
      password: '',
      remember: false
    }
  })

  useEffect(() => {
    if (user) {
      const state = location?.state as LocationCustomState
      const from = state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }
  }, [user])

  const _handleLogin = (data: typeof values) => {
    login({
      ...data,
      onSuccess: () => {
        const state = location?.state as LocationCustomState
        const from = state?.from?.pathname || '/'
        navigate(from, { replace: true })
      },
      onFail: (errorMessage) => {
        notifications.showNotification({
          title: errorMessage?.status || '500',
          message: errorMessage?.message || 'ログインできませんでした',
          color: 'red'
        })
      }
    })
  }

  if (user) return null

  return (
    <>
      <Helmet title="Sign In" />
      <AppShell
        padding="md"
        classNames={{
          main: classes.main
        }}
        fixed
      >
        <Center className={classes.center}>
          <Card shadow="md" p="lg">
            <form onSubmit={onSubmit(_handleLogin)}>
              <TextInput
                required
                label="Email"
                placeholder="example@mail.com"
                size="md"
                className={classes.input}
                mb="md"
                {...getInputProps('email')}
              />
              <PasswordInput
                required
                label="Password"
                size="md"
                className={classes.input}
                {...getInputProps('password')}
              />
              <Checkbox
                label="Remember me"
                size="md"
                mt="md"
                {...getInputProps('remember')}
              />
              <Group position="right" mt="xl">
                <Button
                  type="submit"
                  leftIcon={<Send size={16} />}
                  loading={loading}
                  size="md"
                  fullWidth
                >
                Login
                </Button>
              </Group>
            </form>
          </Card>
        </Center>
      </AppShell>
    </>
  )
}

const useStyles = createStyles((theme) => ({
  main: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[0]
  },
  center: {
    height: '100%'
  },
  input: {
    width: 300
  }
}))
