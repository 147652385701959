import { useLocalStorage } from '@mantine/hooks'
import config from '../config'

interface UseColorScheme {
  colorScheme: 'light'|'dark';
  toggleScheme: () => void
}

export function useColorScheme (): UseColorScheme {
  const [colorScheme, setColorScheme] = useLocalStorage<'light'|'dark'>({ key: config.localStorage.colorScheme, defaultValue: 'light' })

  return {
    colorScheme,
    toggleScheme: () => setColorScheme(current => current === 'light' ? 'dark' : 'light')
  }
}
