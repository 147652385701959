import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import dayjs from 'dayjs'

import { encrypt, decrypt } from './index'
import config from '../config'
import { User } from '../typings'

const getTokens = () => {
  const cookiesList = Cookies.get()
  if (config.cookies.auth in cookiesList) {
    try {
      const decrypted = decrypt(cookiesList[config.cookies.auth])
      const parsed = JSON.parse(decrypted)
      if (parsed) {
        const { access_token, refresh_token } = parsed
        // console.log('access_token ', access_token)
        // console.log('refresh_token ', refresh_token)
        return { access_token, refresh_token }
      }
    } catch (error) {
      console.error('Token error', error)
      // Error while decrypting/parsing tokens, logout user to prevent weird behavior
      logoutUser()
    }
  }
}
const isTokenValid = (token: string, log?: boolean) => {
  if (!token) return false
  try {
    const jwt = jwtDecode<{ exp: number }>(token)
    const expire = jwt?.exp && dayjs.unix(jwt.exp)
    if (log) {
      console.log('jwt', jwt)
      console.log('expire', expire && expire.format('YYYY-MM-DD HH:mm:ss'))
      console.log('valid', dayjs().isBefore(expire))
    }
    return dayjs().isBefore(expire)
  } catch (error) {
    console.log('token validation error', error)
    return false
  }
}
export const deleteTokens = () => {
  for (const key of Object.keys(config.cookies)) {
    const cookie = config.cookies[key as keyof typeof config.cookies]
    Cookies.remove(cookie)
  }
}

export const getAccessToken = () => {
  const tokens = getTokens()
  return tokens?.access_token
}
export const getRefreshToken = () => {
  const tokens = getTokens()
  return tokens?.refresh_token
}
export const isAccessTokenValid = () => {
  return isTokenValid(getAccessToken())
}
export const isRefreshTokenValid = () => {
  return isTokenValid(getRefreshToken())
}
export const logoutUser = () => {
  deleteTokens()
  window.alert('セキュリティの為、もう一度ログインしてください')
  window.location.reload()
}
export const insertCookies = ({ user, access_token, refresh_token }: { user: User, access_token: string, refresh_token: string }) => {
  Cookies.set(config.cookies.auth, encrypt(JSON.stringify({ access_token, refresh_token })))
  Cookies.set(config.cookies.user, encrypt(JSON.stringify(user)))
}

export const updateTokens = (access_token: string, refresh_token: string) => {
  if (access_token && refresh_token) {
    Cookies.set(config.cookies.auth, encrypt(JSON.stringify({ access_token, refresh_token })))
  }
}

export const isAuthenticated = (): User|null => {
  const { auth, user } = config.cookies
  const cookiesList = Cookies.get()
  let userInfo, tokens
  if (user in cookiesList) {
    userInfo = decrypt(cookiesList[user])
    userInfo = userInfo ? JSON.parse(userInfo) : null
  }
  if (auth in cookiesList) {
    tokens = decrypt(cookiesList[auth])
    tokens = tokens ? JSON.parse(tokens) : null
  }

  if (!userInfo || !tokens) {
    deleteTokens()
    return null
  }

  return userInfo
}
