import PageLayout from '../../components/PageLayout/PageLayout'
import CustomerForm from './CustomerForm'

const breadcrumbs = [
  {
    title: 'Customers',
    to: '/customers'
  },
  {
    title: 'Add Customer',
    to: '/customers/add'
  }
]

const initialData = {
  first_name: '',
  last_name: '',
  email: '',
  birthday: '',
  store: '',
  picture: '',
  active: false,
  movie_categories: [],
  memo: ''
}

export default function CustomerAddPage () {
  return (
    <PageLayout
      pageTitle="Add Customer"
      title="Add Customer"
      breadcrumbs={breadcrumbs}
    >
      <CustomerForm
        initialData={initialData}
      />
    </PageLayout>
  )
}
