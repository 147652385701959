import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'

import { loginUser, logoutUser } from '../services'
import { CommonRequest, User } from '../typings'
import { deleteTokens, insertCookies, isAuthenticated } from '../utils/auth'

type AuthContextType = {
  user?: User;
  loading: boolean;
  error?: any;
  login: (params: LoginRequest) => void;
  logout: () => void;
}

type LoginRequest = CommonRequest & {
  email: string;
  password: string;
  remember: boolean;
}

const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
)

// Export the provider as we need to wrap the entire app with it
export function AuthProvider ({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User>()
  const [error, setError] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true)

  const location = useLocation()

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null)
  }, [location.pathname])

  // Check if there is a currently active session
  // when the provider is mounted for the first time.
  // Finally, just signal the component that the initial load
  // is over.
  useEffect(() => {
    const user = isAuthenticated()
    if (user) setUser(user)
    setLoadingInitial(false)
  }, [])

  // Flags the component loading state and posts the login
  // data to the server.
  //
  // An error means that the email/password combination is
  // not valid.
  //
  // Finally, just signal the component that the
  // loading state is over.
  function login ({ email, password, remember, onSuccess, onFail }: LoginRequest) {
    setLoading(true)

    loginUser(email, password)
      .then(res => {
        if (res?.data) {
          setUser(res?.data?.user)
          remember && insertCookies(res.data)
          if (onSuccess) onSuccess()
        } else {
          setError('ユーザー見つかりませんでした')
          if (onFail) onFail({ code: 500, message: 'ユーザー見つかりませんでした' })
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false))
  }

  // Call the logout endpoint and then remove the user
  // from the state.
  function logout () {
    logoutUser().then(() => {
      setUser(undefined)
      deleteTokens()
    })
  }

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      user,
      loading,
      error,
      login,
      logout
    }),
    [user, loading, error]
  )

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export function useAuth () {
  return useContext(AuthContext)
}
