import { useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from '@mantine/notifications'
import { DatePicker } from '@mantine/dates'
import { MIME_TYPES } from '@mantine/dropzone'
import { useForm, yupResolver } from '@mantine/form'
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Group,
  InputWrapper,
  Select,
  Switch,
  Textarea,
  TextInput
} from '@mantine/core'
import { Calendar, Send } from 'tabler-icons-react'
import PropTypes from 'prop-types'

import { postCustomer } from '../../services'
import { addCustomerSchema } from '../../validation'
import { Customer } from '../../typings'

import FilePicker from '../../components/FilePicker/FilePicker'

type Props = {
  initialData: Customer;
}

function CustomerForm ({ initialData }: Props) {
  const navigate = useNavigate()
  const notifications = useNotifications()
  const [posting, setPosting] = useState(false)

  const initialValues = {
    ...initialData,
    birthday: initialData?.birthday && dayjs(initialData.birthday).toDate()
  }

  const { onSubmit, getInputProps, values, setFieldValue } = useForm({
    schema: yupResolver(addCustomerSchema),
    initialValues
  })

  const _handleSubmit = async (data: typeof values) => {
    setPosting(true)
    console.log('post', data)
    try {
      await postCustomer(data)
      navigate('/customers')
    } catch (error:any) {
      notifications.showNotification({
        title: error?.status || '500',
        message: error?.message || '登録できませんでした',
        color: 'red'
      })
    } finally {
      setPosting(false)
    }
  }

  return (
    <Box py="lg">
      <form onSubmit={onSubmit(_handleSubmit)}>
        <TextInput
          label="First Name"
          placeholder="太郎"
          size="md"
          mb="md"
          {...getInputProps('first_name')}
        />
        <TextInput
          label="Last Name"
          placeholder="山田"
          size="md"
          mb="md"
          {...getInputProps('last_name')}
        />
        <TextInput
          label="Email"
          placeholder="example@mail.com"
          size="md"
          mb="md"
          {...getInputProps('email')}
        />
        <DatePicker
          placeholder="Pick date"
          label="Birthday"
          icon={<Calendar size={16} />}
          allowFreeInput
          initialMonth={dayjs(new Date()).subtract(16, 'year').toDate()}
          minDate={dayjs(new Date()).startOf('year').subtract(100, 'year').toDate()}
          maxDate={dayjs(new Date()).endOf('year').subtract(16, 'year').toDate()}
          {...getInputProps('birthday')}
        />
        <Select
          label="Shop"
          placeholder="Pick one"
          searchable
          nothingFound="No options"
          clearable
          data={[
            { value: '1', label: '店１' },
            { value: '2', label: '店２' }
          ]}
          {...getInputProps('store')}
        />
        <FilePicker
          name="picture"
          label="Picture"
          acceptType={[MIME_TYPES.png, MIME_TYPES.jpeg]}
          getInputProps={getInputProps}
          setFieldValue={setFieldValue}
        />
        <InputWrapper
          label="Active"
          size="md"
          my="md"
        >
          <Switch
            {...getInputProps('active')}
          />
        </InputWrapper>
        <CheckboxGroup
          label="Favorite movie categories"
          orientation="vertical"
          spacing="sm"
          size="md"
          mb="md"
          {...getInputProps('movie_categories')}
        >
          <Checkbox value='1' label='Action' />
          <Checkbox value='2' label='Animation' />
          <Checkbox value='3' label='Children' />
          <Checkbox value='4' label='Classics' />
          <Checkbox value='5' label='Comedy' />
          <Checkbox value='6' label='Documentary' />
          <Checkbox value='7' label='Drama' />
          <Checkbox value='8' label='Family' />
          <Checkbox value='9' label='Foreign' />
          <Checkbox value='10' label='Games' />
          <Checkbox value='11' label='Horror' />
          <Checkbox value='12' label='Music' />
          <Checkbox value='13' label='New' />
          <Checkbox value='14' label='Sci' />
          <Checkbox value='15' label='Sports' />
          <Checkbox value='16' label='Travel' />
        </CheckboxGroup>
        <Textarea
          label="Memo"
          size="md"
          mb="md"
          {...getInputProps('memo')}
        />
        <Group position="right" mt="xl">
          <Button
            type="submit"
            leftIcon={<Send size={16} />}
            size="md"
            loading={posting}
          >保存</Button>
        </Group>
      </form>
    </Box>
  )
}

CustomerForm.propTypes = {
  initialData: PropTypes.object
}

export default CustomerForm
