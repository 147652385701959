import flatten from 'flat'

export const isObject = (variable: unknown): boolean => {
  return Object.prototype.toString.call(variable) === '[object Object]'
}
/**
 * Takes an object and test if all properties are not falsy ()
 * @param object
 */
export const verify = (object: unknown): string[]|null => {
  if (object && isObject(object)) {
    const data = flatten(object) as any
    const invalid = []
    for (const key in data) {
      const value = data[key]
      if (value == null || Number.isNaN(value)) {
        console.error(`${key} is not set`)
        invalid.push(key)
      }
    }
    return invalid
  }
  return null
}
