import config from '../config'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Console {
    debug(message?: any, ...optionalParams: any[]): void
    test(message?: any, ...optionalParams: any[]): void
    info(message?: any, ...optionalParams: any[]): void
    success(message?: any, ...optionalParams: any[]): void
    danger(message?: any, ...optionalParams: any[]): void
    warning(message?: any, ...optionalParams: any[]): void
    formData(formData: any): void
  }
}

const logStyles = [
  'border-radius: 3px',
  'color: white',
  'font-weight: bold',
  'padding: 2px 5px'
]
const customizedStyle = (type?: string) => {
  const style = [...logStyles]
  switch (type) {
    case 'debug':
      style.push('background: #555')
      break
    case 'info':
      style.push('background: #1890FF')
      break
    case 'success':
      style.push('background: #52C41A')
      break
    case 'danger':
      style.push('background: #D33F3F')
      break
    case 'warning':
      style.push('background: #FFC82C')
      break
    default:
      break
  }
  return style.join(';')
}
// Console log helper for multipart/form-data
const formDataLog = (formData: any) => {
  console.group('%cFormData%c', customizedStyle('warning'), 'color: inherit;')
  for (const pair of formData.entries()) {
    console.log(`%c${pair[0]}%c`, customizedStyle('info'), 'color: inherit', pair[1])
  }
  console.groupEnd()
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {}

export const initializeConsole = () => {
  const _warn = console.warn
  const _log = console.log
  const _error = console.error

  console.debug = (function () {
    if (!config.debug) return noop
    return Function.prototype.bind.call(_log, console, '%cDebug%c', customizedStyle('debug'), 'color: inherit')
  }())
  console.test = (function () {
    if (config.debug) return noop
    return Function.prototype.bind.call(_log, console, '%cDebug%c', customizedStyle('debug'), 'color: inherit')
  }())
  console.info = (function () {
    if (config.debug) return noop
    return Function.prototype.bind.call(_log, console, '%cInfo%c', customizedStyle('info'), 'color: inherit')
  }())
  console.success = (function () {
    if (config.debug) return noop
    return Function.prototype.bind.call(_log, console, '%cSuccess%c', customizedStyle('success'), 'color: inherit')
  }())
  console.danger = (function () {
    if (config.debug) return noop
    return Function.prototype.bind.call(_error, console, '%cError%c', customizedStyle('danger'), 'color: inherit')
  }())
  console.warning = (function () {
    if (config.debug) return noop
    return Function.prototype.bind.call(_warn, console, '%cWarning%c', customizedStyle('warning'), 'color: inherit')
  }())

  console.formData = formDataLog
}
