import { AxiosResponse } from 'axios'
import { User } from '../typings'
import { api } from '../utils'

type LoginResponse = {
  user: User;
  access_token: string;
  refresh_token: string;
}

export const loginUser = async (email: string, password: string): Promise<AxiosResponse<LoginResponse>> => {
  return api.post<LoginResponse>('/login', { email, password })
}
export const logoutUser = async (): Promise<AxiosResponse<unknown>> => {
  return api.post<unknown>('/logout')
}
