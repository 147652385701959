import * as yup from 'yup'

export const addCustomerSchema = yup.object().shape({
  first_name: yup.string()
    .nullable()
    .label('First name'),
  last_name: yup.string()
    .nullable()
    .label('Last name'),
  email: yup.string()
    .email()
    .nullable()
    .label('Email'),
  birthday: yup.date()
    .nullable()
    .label('Birthday'),
  store: yup.string()
    .nullable()
    .label('Store'),
  picture: yup.mixed()
    .nullable()
    .label('Picture'),
  active: yup.bool()
    .nullable()
    .label('Active'),
  movie_categories: yup.array()
    .of(yup
      .string()
      .nullable()
    )
    .nullable()
    .label('Active'),
  memo: yup.string()
    .nullable()
    .label('Memo')
})
