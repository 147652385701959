import { useEffect, useState } from 'react'
import MD5 from 'crypto-js/md5'
import { createStyles, Divider, Menu, Text } from '@mantine/core'
import { Logout, Moon, Sun } from 'tabler-icons-react'

import UserButton from './UserButton'
import { useAuth, useColorScheme } from '../../hooks'

export default function UserMenu () {
  const { classes } = useStyles()
  const { colorScheme, toggleScheme } = useColorScheme()
  const { user, logout } = useAuth()
  const [userAvatar, setUserAvatar] = useState<string>('')

  useEffect(() => {
    if (user) {
      const { email } = user
      const hash = MD5(email).toString()
      setUserAvatar(`https://www.gravatar.com/avatar/${hash}?d=identicon`)
    }
  }, [user])

  return (
    <Menu
      withArrow
      placement="center"
      control={<UserButton image={userAvatar} name={user?.displayName} email={user?.email} />}
      size="auto"
    >
      <Menu.Item
        icon={colorScheme === 'dark' ? <Moon size={14} /> : <Sun size={14} />}
        rightSection={<Text size="xs" color="dimmed" className={classes.shortcut}>⌘J</Text>}
        onClick={toggleScheme}
      >
        Toggle {colorScheme === 'dark' ? 'Light' : 'Dark'} Mode
      </Menu.Item>
      <Divider />
      <Menu.Item color="red" onClick={logout} icon={<Logout size={14} />}>Logout</Menu.Item>
    </Menu>
  )
}

const useStyles = createStyles((theme) => ({
  shortcut: {
    marginLeft: theme.spacing.md
  }
}))
