import { Container, createStyles, Group, LoadingOverlay, Title } from '@mantine/core'
import PropTypes from 'prop-types'

import Breadcrumbs, { Breadcrumb } from '../Breadcrumbs/Breadcrumbs'
import Helmet from '../Helmet/Helmet'

type Props = {
  title?: string;
  pageTitle?: string;
  breadcrumbs?: Breadcrumb[];
  titleRightArea?:React.ReactNode;
  loading?: boolean;
  children: React.ReactNode;
}

function PageLayout ({ loading, pageTitle, title, titleRightArea, breadcrumbs, children }: Props) {
  const { classes } = useStyles()
  const RightArea = titleRightArea || null

  return (
    <>
      <Helmet title={pageTitle || title} />
      <Container size="xl" className={classes.mainContainer}>
        <Breadcrumbs items={breadcrumbs} />
        <Group position="apart" spacing="sm" className={classes.mainGroup}>
          <Group grow>
            { title && <Title className={classes.title}>{title}</Title> }
          </Group>
          <Group>
            { RightArea }
          </Group>
        </Group>
        <LoadingOverlay visible={!!loading}/>
        {children}
      </Container>
    </>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string,
  pageTitle: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  })),
  titleRightArea: PropTypes.node,
  loading: PropTypes.bool
}

const useStyles = createStyles((theme) => ({
  title: {
    color: theme.colorScheme === 'dark' ? theme.colors.teal[0] : theme.colors.teal[7],
    fontFamily: theme.fontFamily,
    fontWeight: 400
  },
  mainContainer: {
    height: '100%'
  },
  mainGroup: {
    marginBottom: theme.spacing.md
  }
}))

export default PageLayout
