import { Breadcrumbs as BreadcrumbsContainer, Anchor, createStyles } from '@mantine/core'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

export type Breadcrumb = {
  title: string;
  to: string;
}

type Props = {
  items?: Breadcrumb[]
}
function Breadcrumbs ({ items }: Props) {
  const { classes } = useStyles()
  return (
    <BreadcrumbsContainer mb="md">
      <Anchor component={NavLink} to='/' underline={false} end>
        <div className={classes.anchors}>
          <span className={classes.homeAnchor}>Home</span>
        </div>
      </Anchor>
      {
        items && items.map((item, index) => (
          <Anchor
            underline={false}
            component={NavLink}
            to={item.to}
            key={index}
            sx={() => ({
              '&.active': {
                pointerEvents: 'none',
                cursor: 'none'
              }
            })}
            end
          >
            <div className={classes.anchors}>
              <span>{item.title}</span>
            </div>
          </Anchor>
        ))
      }
    </BreadcrumbsContainer>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }))
}

const useStyles = createStyles((theme) => ({
  homeIcon: {
    // transform: 'translateY(1px)'
  },
  homeAnchor: {
    marginLeft: theme.spacing.xs / 1.25
  },
  anchors: {
    color: theme.colorScheme === 'dark' ? theme.colors.teal[4] : theme.colors.teal[9],
    'a.active &': {
      color: theme.colorScheme === 'dark' ? theme.colors.teal[2] : theme.colors.teal[5],
      pointerEvents: 'none',
      cursor: 'none'
    },
    '& > span': {
      color: 'inherit'
    }
  },
  anchorLink: {
    opacity: 0.25
  }
}))

export default Breadcrumbs
