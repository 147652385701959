import CryptoJS from 'crypto-js'
import config from '../config'

export const encrypt = function (messageToEncrypt: string) {
  return CryptoJS.AES.encrypt(messageToEncrypt, config.encryption.key).toString()
}

export const decrypt = function (encryptedMessage: string) {
  const bytes = CryptoJS.AES.decrypt(encryptedMessage, config.encryption.key)
  return bytes && bytes.toString(CryptoJS.enc.Utf8)
}
