import { Center, Container, createStyles, Title } from '@mantine/core'

import Helmet from '../components/Helmet/Helmet'

export default function NotFound () {
  const { classes } = useStyles()
  return (
    <>
      <Helmet title="Page not found" />
      <Container fluid className={classes.container}>
        <Center className={classes.center}>
          <Title
            className={classes.mainTitle}
          >404</Title>
          <Title
            order={3}
            className={classes.subTitle}
          >The page your requested could not be found.</Title>
        </Center>
      </Container>
    </>
  )
}

const useStyles = createStyles((theme) => ({
  container: {
    height: '100%'
  },
  center: {
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  },
  mainTitle: {
    color: theme.colors.teal[8]
  },
  subTitle: {
    color: theme.colors.teal[6],
    textAlign: 'center'
  }
}))
