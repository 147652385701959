import { useEffect, useState } from 'react'
import {
  Group,
  Text,
  useMantineTheme,
  MantineTheme,
  InputWrapper,
  Image,
  ActionIcon,
  createStyles
} from '@mantine/core'
import { Dropzone, DropzoneStatus, MIME_TYPES } from '@mantine/dropzone'
import { Upload, Photo, X, Icon as TablerIcon } from 'tabler-icons-react'
import PropTypes from 'prop-types'
import { useNotifications } from '@mantine/notifications'

type Props = {
  name: string;
  label: string;
  maxSize?: number;
  acceptType?: string[];
  getInputProps: any;
  setFieldValue: any;
}

function getIconColor (status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
    : status.rejected
      ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
      : theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7]
}

function ImageUploadIcon ({
  status,
  ...props
}: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />
  }

  if (status.rejected) {
    return <X {...props} />
  }

  return <Photo {...props} />
}

const dropzoneChildren = (status: DropzoneStatus, theme: MantineTheme, maxSize: number) => (
  <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
    <ImageUploadIcon status={status} style={{ color: getIconColor(status, theme) }} size={80} />

    <div>
      <Text size="xl" inline>
        Drag images here or click to select files
      </Text>
      <Text size="sm" color="dimmed" inline mt={7}>
        Attach as many files as you like, each file should not exceed {maxSize || 5}mb
      </Text>
    </div>
  </Group>
)

function FilePicker ({ name, label, maxSize = 10, acceptType, getInputProps, setFieldValue }: Props) {
  const notifications = useNotifications()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const { value } = getInputProps(name)
  const [previewFile, setPreviewFile] = useState<string>()

  useEffect(() => {
    // Create a preview URL only if it's a local file
    if (value && typeof value === 'object') {
      setPreviewFile(URL.createObjectURL(value))
    }
    return () => {
      if (previewFile) {
        previewFile && URL.revokeObjectURL(previewFile)
      }
    }
  }, [value])

  const _onDrop = (files: File[]) => {
    setFieldValue(name, files[0])
  }
  const _onReject = () => {
    notifications.showNotification({
      title: 'File error',
      message: 'The file could not be processed. \nPlease check again the size and format of your file',
      color: 'red'
    })
  }
  const _onReset = () => {
    setFieldValue(name, null)
    setPreviewFile(undefined)
  }

  return (
    <InputWrapper
      label={label}
      size="md"
      mt="md"
    >
      <Dropzone
        name={name}
        onDrop={_onDrop}
        onReject={_onReject}
        maxSize={maxSize * 1024 ** 2}
        accept={acceptType}
      >
        {(status) => dropzoneChildren(status, theme, maxSize)}
      </Dropzone>
      {
        previewFile && (
          <Group mt="md" className={classes.previewContainer}>
            <ActionIcon
              onClick={_onReset}
              variant="filled"
              color="red"
              classNames={{
                root: classes.closeIcon
              }}
            >
              <X size={16} />
            </ActionIcon>
            <Image
              src={previewFile}
              width={400}
              fit="contain"
              radius="xl"
              withPlaceholder
            />
          </Group>
        )
      }
    </InputWrapper>
  )
}

FilePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
  acceptType: PropTypes.arrayOf(PropTypes.oneOf(Object.values(MIME_TYPES))),
  getInputProps: PropTypes.any,
  setFieldValue: PropTypes.any
}

const useStyles = createStyles(() => ({
  previewContainer: {
    position: 'relative',
    display: 'inline-flex'
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 10
  }
}))

export default FilePicker
