import { useEffect, useRef, useReducer, useCallback } from 'react'
import dayjs from 'dayjs'
import { stringifyUrl, ParsedQuery } from 'query-string'

import { api } from '../utils'

enum ActionKind {
  IDLE = 'idle',
  LOADING = 'loading',
  DONE = 'done',
  ERROR = 'error',
}
type Action = {
  type: ActionKind;
  payload?: any;
}
type ApiRequest = {
  url: string;
  params?: ParsedQuery;
}
type ApiResponse<Response> = {
  status: typeof ActionKind.IDLE | typeof ActionKind.LOADING | typeof ActionKind.DONE | typeof ActionKind.ERROR;
  isLoading: boolean;
  error: unknown;
  data: Response|null;
  code: number|null;
}
type Cache = {
  [key: string]: {
    data: any;
    expiresAt: Date;
  }
}

const initialState = {
  status: ActionKind.IDLE,
  isLoading: false,
  error: null,
  data: null,
  code: null
}

function isCacheValid (expiresAt: Date) {
  if (!expiresAt || !dayjs(expiresAt).isValid) return false

  const now = dayjs()
  return now.isBefore(dayjs(expiresAt))
}

export function useFetch<Response = unknown> ({ url, params }: ApiRequest): ApiResponse<Response> {
  // Will hold the cache for each GET request
  const cache = useRef<Cache>({})
  const queryUrl = stringifyUrl({ url, query: params })

  // A simple reducer function to manage the status & response of the request
  function reducer (state: ApiResponse<Response>, action: Action): ApiResponse<Response> {
    switch (action.type) {
      case ActionKind.LOADING:
        return { ...initialState, status: ActionKind.LOADING, isLoading: true }
      case ActionKind.DONE:
        return { ...initialState, status: ActionKind.DONE, isLoading: false, data: action.payload }
      case ActionKind.ERROR:
        return { ...initialState, status: ActionKind.ERROR, isLoading: false, error: action.payload }
      default:
        return state
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  // Request handler
  const fetchData = useCallback(
    async (cancelRequest: boolean, signal: AbortSignal) => {
      dispatch({ type: ActionKind.LOADING })
      try {
        const response = await api.get(queryUrl, {
          signal
        })
        cache.current[queryUrl] = {
          data: response?.data,
          expiresAt: dayjs().add(1, 'minute').toDate()
        }

        dispatch({ type: ActionKind.DONE, payload: response?.data })
      } catch (error: any) {
        if (cancelRequest) return
        dispatch({ type: ActionKind.ERROR, payload: error?.message })
      }
    },
    []
  )

  // Process the request everytime the url, query or data changes
  useEffect(() => {
    let cancelRequest = false
    const controller = new AbortController()
    if (!url || !url.trim()) return

    // Check cache
    if (cache.current[queryUrl] && isCacheValid(cache.current[queryUrl]?.expiresAt)) {
      dispatch({ type: ActionKind.DONE, payload: cache.current[queryUrl]?.data })
    } else {
      fetchData(cancelRequest, controller.signal)
    }

    return function cleanup () {
      cancelRequest = true
      controller.abort()
    }
  }, [url, params])

  return state
}
