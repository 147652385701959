import { MantineThemeOverride, MantineTheme } from '@mantine/core'

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  defaultRadius: 'sm',
  white: '#f9f9f9',
  black: '#1e1e1e',
  colors: {
    yellow: [
      '#ffd700'
    ],
    darkblue: [
      '#363B44',
      '#29303C',
      '#1E2736',
      '#182334',
      '#131F32',
      '#141D2A',
      '#141A24',
      '#13181F',
      '#111417',
      '#101214'
    ]
  },
  primaryColor: 'teal',
  fontFamily: 'Noto Sans JP, sans-serif',
  // lineHeight: 16,
  transitionTimingFunction: 'ease-in-out',
  fontSizes: {},
  radius: {},
  spacing: {
    lg: 32
  },
  shadows: {},
  breakpoints: {},
  headings: {
    fontFamily: 'Noto Serif JP, serif',
    fontWeight: 700,
    sizes: {
      h1: {
        fontSize: 39.81,
        lineHeight: 1.3
      },
      h2: {
        fontSize: 33.18,
        lineHeight: 1.3
      },
      h3: {
        fontSize: 27.65,
        lineHeight: 1.3
      },
      h4: {
        fontSize: 23.04,
        lineHeight: 1.3
      },
      h5: {
        fontSize: 19.20,
        lineHeight: 1.3
      },
      h6: {
        fontSize: 16,
        lineHeight: 1.3
      }
    }
  },
  dir: 'ltr',
  loader: 'bars',
  dateFormat: 'YYYY/MM/DD',
  datesLocale: 'jp',
  other: {}
}

export const styles = {
  TextInput: (theme: MantineTheme) => ({
    root: {
      marginBottom: theme.spacing.sm
    }
  }),
  Autocomplete: (theme: MantineTheme) => ({
    root: {
      marginBottom: theme.spacing.sm
    }
  }),
  Select: (theme: MantineTheme) => ({
    root: {
      marginBottom: theme.spacing.sm
    }
  }),
  DateRangePicker: (theme: MantineTheme) => ({
    root: {
      marginBottom: theme.spacing.sm
    }
  })
}
