import { Navigate, useLocation, BrowserRouter, Route, Routes } from 'react-router-dom'

import { AuthProvider, useAuth } from '../hooks'

import MainLayout from './MainLayout'
import NotFound from '../pages/NotFound'
import LoginPage from '../pages/LoginPage'
import HomePage from '../pages/HomePage'
import CustomersPage from '../pages/customers/CustomersPage'
import CustomerPage from '../pages/customers/CustomerPage'
import CustomerAddPage from '../pages/customers/CustomerAddPage'

function PrivateRoute ({ children }: { children: JSX.Element }) {
  const { user } = useAuth()
  const location = useLocation()

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they sign in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  return children
}

export default function Router () {
  return (
    <BrowserRouter>
      <AuthProvider>
          <Routes>
            {/* <Route path='*' element={<NotFound />} /> */}
            <Route path="/login" element={<LoginPage />} />
            <Route element={<MainLayout />}>
              <Route path='*' element={<NotFound />} />
              <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>}/>
              <Route path="/customers" element={<PrivateRoute><CustomersPage /></PrivateRoute>}/>
              <Route path="/customers/add" element={<PrivateRoute><CustomerAddPage /></PrivateRoute>}/>
              <Route path="/customers/:customerId" element={<PrivateRoute><CustomerPage /></PrivateRoute>}/>
            </Route>
          </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}
