import { useState, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Anchor, Button, createStyles, Group, Popover, Text } from '@mantine/core'

type Props = {
  children: React.ReactElement;
  message?: string;
  onConfirm: () => void;
  onConfirmText?: string;
  onConfirmColor?: string;
}

function Confirm ({ children, message, onConfirm, onConfirmText, onConfirmColor }: Props) {
  const { classes } = useStyles()
  const [opened, setOpened] = useState(false)

  const _toggleOpen = () => {
    setOpened((o) => !o)
  }

  const _onConfirm = () => {
    onConfirm && onConfirm()
    _toggleOpen()
  }

  if (!children) return null

  return (
    <Popover
      opened={opened}
      onClose={() => setOpened(false)}
      placement="center"
      position="top"
      transition="pop-top-right"
      target={<>{cloneElement(children, { onClick: _toggleOpen })}</>}
      classNames={{ root: classes.root, popover: classes.popover }}
      shadow="xl"
    >
      <Group position="center">
        <Text size="sm" className={classes.message}>{message || 'Are you sure?'}</Text>
      </Group>
      <Group position="apart" className={classes.buttonsGroup}>
        <Anchor component="button" color="gray" size="xs" onClick={_toggleOpen}>
          Cancel
        </Anchor>
        <Button
          type="button"
          size="xs"
          color={onConfirmColor}
          variant="outline"
          onClick={_onConfirm}
        >{onConfirmText || 'Ok'}</Button>
      </Group>
    </Popover>
  )
}

Confirm.propTypes = {
  message: PropTypes.string,
  onConfirmText: PropTypes.string,
  onConfirmColor: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

const useStyles = createStyles((theme) => ({
  root: {
    display: 'block'
  },
  popover: {
    background: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0]
  },
  message: {
    color: theme.colorScheme === 'dark' ? theme.colors.teal[0] : theme.colors.teal[7],
    whiteSpace: 'pre-wrap'
  },
  buttonsGroup: {
    marginTop: 15
  }
}))

export default Confirm
