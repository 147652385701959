import { Anchor, createStyles, Text } from '@mantine/core'
import { NavLink } from 'react-router-dom'
import { Home, Users, Icon } from 'tabler-icons-react'

type Props = {
  path: string;
  label: string;
  icon: Icon;
}

function Link ({ path, label, icon }: Props) {
  const { classes } = useStyles()
  const CustomIcon = icon
  return (
    <Anchor align="center" component={NavLink} to={path} className={classes.link}>
      <CustomIcon
        size={22}
        strokeWidth={1}
        className={classes.linkIcon}
      />
      <Text>{label}</Text>
    </Anchor>
  )
}

export default function Links () {
  return (
    <>
      <Link
        path="/"
        label="Home"
        icon={Home}
      />
      <Link
        path="/customers"
        label="Customers"
        icon={Users}
      />
    </>
  )
}

const useStyles = createStyles((theme) => ({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: 1.7,
    color: theme.colors.gray[0],
    position: 'relative',
    '&.active': {
      color: theme.colors.yellow[0],
      textDecoration: 'none'
    },
    [theme.fn.smallerThan('sm')]: {
      marginBottom: 12
    }
  },
  linkIcon: {
    marginRight: theme.spacing.xs / 1.25
  }
}))
